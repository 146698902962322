import apiAuth from "@/apis/auth/auth";
import orchestrator from "@/apis/auth/starlink";
import starlink from "@/apis/starlink/starlink";
import flask from "@/apis/aplicationFlask/aplication-flask";
import generalFunctions from "@/apis/generalFunctions/general-functions";
import app from "@/apis/aplication/aplication";
import mail from "@/apis/mail/mail";
import google from "@/apis/google/google";
import grafana from "@/apis/grafana/grafana";
import grafanaGraphics from "@/apis/grafana/grafana-graphics";
import router from "@/router";
import {
  getToken,
  getTokenRefresh,
  setToken,
  setTokenRefresh,
  removeToken,
} from "@/utils/auth";
import incident from "@/apis/incident/incident";
import { jwtDecode } from "jwt-decode";
export default {
  state: {
    user: null,
    userDecode: null,
    token: getToken(),
    tokenRefresh: getTokenRefresh(),
    permision: null,
    coordenateData: [],
    myColumns: [],
  },
  getters: {
    islogin: (state: { user: any }) => {
      if (!state.user) {
        return false;
      }
      return true;
    },
    permissions: (state: { permision: any }) => {
      if (!state.permision) {
        return [];
      }
      return state.permision;
    },
  },
  mutations: {
    SET_USER: (state: { user: any }, values: any) => {
      state.user = values;
    },
    SET_USERDECODE: (state: { userDecode: any }, values: any) => {
      state.userDecode = jwtDecode(values);
      console.log(state.userDecode);
    },
    SET_PERMISION: (state: { permision: any }, values: any) => {
      state.permision = values;
    },
    SET_MYCOLUMS: (state: { myColumns: any }, values: any) => {
      state.myColumns = values;
    },
    SET_COORDENATE: (state: { coordenateData: any }, values: any) => {
      state.coordenateData = values;
    },
    SET_query: (state: { query: any }, values: any) => {
      state.query.text = values;
    },
    SET_column: (state: { dataFilter: any }, values: string) => {
      state.dataFilter.column = values;
    },
    SET_mode: (state: { dataFilter: any }, values: string) => {
      state.dataFilter.mode = values;
    },
  },
  actions: {
    LOGIN: (rootstate: any, user: any) => {
      return new Promise((resolve, reject) => {
        apiAuth
          .login(user)
          .then((response) => {
            setToken(response.data.accessToken);
            setTokenRefresh(response.data.refreshToken);
            rootstate.commit("SET_USERDECODE", response.data.accessToken);
            rootstate
              .dispatch("PERMISION", { type: 2, endpoint: 40 })
              .then((user: any) => {
                rootstate.dispatch("showToast", {
                  message: "Inicio de sesion correcto",
                  type: "success",
                });
                router.push("/home");
              })
              .catch((erroruser: any) => {
                console.log("error", erroruser);
              });
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      }).catch((erroruser: any) => {
        console.log("error", erroruser);
      });
    },
    REFRESH_TOKEN: (rootstate: any, token: any) => {
      return new Promise((resolve, reject) => {
        apiAuth
          .refresh_token(token)
          .then((response) => {
            setToken(response.data.accessToken);
            setTokenRefresh(response.data.refreshToken);
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    PERMISION: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        apiAuth
          .permision(data)
          .then((response) => {
            rootstate.commit("SET_PERMISION", response.data.data.web);
            rootstate.commit("SET_USER", response.data.data.user);
            rootstate.commit("SET_USERDECODE", getToken());
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    LOGOUT: (rootstate: any) => {
      return new Promise((resolve, reject) => {
        rootstate.commit("SET_USER", null);
        rootstate.commit("SET_PERMISION", null);
        removeToken();
        router.push("/");
      });
    },
    UPDATED: (rootstate: any, user: any) => {
      var newdata = {
        email: user.email,
        fullname: user.fullname,
      };
      return new Promise((resolve, reject) => {
        apiAuth
          .update(user.id, newdata)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    UPDATED_PASSWORD: (rootstate: any, user: any) => {
      var newdata = {
        password: user.password,
      };
      return new Promise((resolve, reject) => {
        apiAuth
          .update(user.id, newdata)
          .then((response) => {
            rootstate.commit("SET_USER", null);
            removeToken();
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    // HELLOWORD: (rootstate: any) => {
    //   return new Promise((resolve, reject) => {
    //     orchestrator
    //       .helloword()
    //       .then((response) => {
    //         console.log(response);
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    // SERVICELINE: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     starlink
    //       .servicelinenew(data.page, data.limit,data.column,data.order,data.type)
    //       .then((response) => {
    //         rootstate.commit("SET_arrayData", response.data);
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    SERVICELINEUSERCONFIG: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        app
          .getDataConfig(data)
          .then((response) => {
            if (data.endpoint === 16) {
              rootstate.commit("SET_arrayData", response.data.data);
            }

            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },

    SERVICELINEUSERCONFIGV3: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        app
          .getDataConfigv3(data)
          .then((response) => {
            if (data.endpoint===16) {
              rootstate.commit("SET_arrayData", response.data.data);
            }

            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    // SERVICEPLAN: (rootstate: any, data: any) => {
    //   return new Promise((resolve, reject) => {
    //     starlink
    //       .serviceplan(data)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((err) => {
    //         if (err) {
    //           reject(false);
    //         }
    //       });
    //   });
    // },
    CUSTOMER: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        starlink
          .customer(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    CREATE_REQUEST: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        flask
          .createRequest(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    UPDATE_REQUEST_STATUS: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        flask
          .updateRequestStatus(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    UPDATE_PLANTA_ALL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        flask
          .updatePlantaAll(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    ENVIAR_EMAILS: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        generalFunctions
          .enviarEmails(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GET_COORDENADE: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        google
          .getCoordenateByAddress(data)
          .then((response) => {
            rootstate.commit("SET_COORDENATE", response.data.features);
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GET_VALID_COORDENADE: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        orchestrator
          .validAddressStarlink(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(err);
            }
          });
      });
    },
    GET_VALID_COORDENADE_PUT: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        orchestrator
          .validAddressStarlinkput(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(err);
            }
          });
      });
    },
    SEND_MAIL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        mail
          .sendmail(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    SERIALNUMBER: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        starlink
          .serialnumber(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    SOPORTE: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        incident
          .soporte(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      }).catch((erroruser: any) => {
        console.log("error", erroruser);
      });
    },
    PROBLEMTYPE: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        incident
          .problemtype(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    CREATEDSUPPORT: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        incident
          .createdSupport(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(err);
            }
          });
      });
    },
    GETMONITORINGURL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafanaGraphics
          .getMonitoringUrl(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGNEWURL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafanaGraphics
          .getMonitoringNewUrl(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLPRINCIPAL: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafanaGraphics
          .getMonitoringUrlPrincipal(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    GETMONITORINGURLPRINCIPALDOS: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        grafanaGraphics
          .getMonitoringUrlPrincipalTerminal(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(false);
            }
          });
      });
    },
    CONFIG_FUNCTION: (rootstate: any, data: any) => {
      return new Promise((resolve, reject) => {
        app
          .functionConfig(data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            if (err) {
              reject(err);
            }
          });
      });
    },
  },
};
